import { computed, onMounted, onUnmounted, nextTick, ref } from 'vue'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~~/tailwind.config'

const useIsMobile = () => {
  const theme = resolveConfig(tailwindConfig).theme
  const width = ref()

  const isMobile = computed(() => {
    const mdBreakpoint = +theme.screens.md.replace('px', '')
    return width.value < mdBreakpoint
  })

  const getDimensions = () => {
    width.value = window.innerWidth
  }

  onMounted(() => {
    width.value = window.innerWidth

    nextTick(() => {
      window.addEventListener('resize', getDimensions)
    })
  })

  onUnmounted(() => {
    window.removeEventListener('resize', getDimensions)
  })

  return { isMobile }
}

export default useIsMobile

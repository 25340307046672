<template>
  <div v-if="!isMobile">
    <slot />
  </div>
  <div v-else class="h-full w-full py-5 text-center text-2xl font-bold text-white sm:text-3xl">
    <span> Mobile devices are not yet supported </span>
  </div>
</template>

<script setup lang="ts">
import useIsMobile from '~/composables/useIsMobile'

const { isMobile } = useIsMobile()
</script>
